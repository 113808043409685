import axios from "axios";
import {
  Message
} from 'ant-design-vue';
// import store from "@/store";
import {
  getToken,
  setToken,
  TOKEN_HEADER
} from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers[TOKEN_HEADER] = getToken();
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    const token = response.headers[TOKEN_HEADER] || null;
    if (typeof token !== "undefined" && token !== null) {
      setToken(response.headers[TOKEN_HEADER]);
    }
    if (res.code && res.code !== 200 && res.code !== 0) {
      Message.error({
        content: res.message || "系统错误"
      });
      return Promise.reject(new Error(res.message || "系统错误"));
    } else {
      return res;
    }
  },
  error => {
    let message = "系统错误";
    console.log("error", error.message, error.status, error.code);
    if (typeof error.response === "undefined") {
      if (error.code === "ECONNABORTED") {
        Message.error({
          content: "请求超时"
        });
      }
      return Promise.reject(error);
    }
    switch (error.response.status) {
      case 404:
        message = "资源不存在";
        break;
      case 403:
        message = "您无权进行此操作";
        break;
      case 401:
        message = "登录失效,请重新登录";
        break;
      default:
        message = error.response.data.message || message;
    }
    Message.error({
      content: message
    });
    return Promise.reject(error);
  }
);

export default service;